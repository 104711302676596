<template>
    <div class="jion">
      <div class="cn">
        <div class="chang">
          <div class="item" :class="active == 0 && 'active'" @click="active=0">
            区域代表
          </div>
          <div class="item" :class="active == 1 && 'active'" @click="active=1">
            代理信息表
          </div>
        </div>
        <div class="active0" v-show="active == 0">
          <p>在满足合作条件的情况下,“明·九章”授权对方在所属行政区域使用本品牌，开展线上学员招募,并负责学员的线下服务工作。</p>
          <p class="name">“明·九章”提供：</p>
          <p>1.学员注册后，每生一份纸质材料，并开通课程视频；</p>
          <p>2.提供线下上课所用PPT；</p>
          <p>3.提供线上检测、每日一练挑战赛服务；</p>
          <p>4.分享网课收益返点：当地网校收入的50%<p>
          <p> 5.区域保护（每个地区只限一家）<p>
          <p class="name">代理机构：</p>
          <p>1.积极开展学员招募、注册、学习活动；</p>
          <p>2.积极组织当地学员参加明·九章开展的系列活动；</p>
          <p>3.根据明·九章提供的教学资源,为会员提供个性化指导服务。相关服务需严格遵照教育部政策的规定,不做违规教学。对于任何违背教育部政策的行为,须负全部责任；</p>
          <p>4.在制作各类策划方案时,有权就需要的文件或资料,向明·九章提出咨询要求；</p>
          <p>5.需具备本地区超过60人学员招募能力。</p>
        </div>

        <div class="active1" v-show="active == 1">
          <p>1.公司名称</p>
          <input v-model="info.companyName"/>
          <p>2.统一社会信用代码</p>
          <input v-model="info.certId"/>
          <p>3.校长姓名</p>
          <input v-model="info.principalName"/>
          <p>4.手机号码：</p>
          <input v-model="info.mobile" maxlength="11"/>
          <p>5.身份证号码</p>
          <input v-model="info.cardId" maxlength="18"/>
          <p>6.代理的区域：</p>
          <input v-model="info.agentArea"/>
          <p>7.机构运营情况（每期培训学生人数）</p>
          <input v-model="info.perTrainNumber"/>
          <p>8.机构合同、教材邮寄地址及联系人（请填写详细具体地址，以便合同能按时送达）</p>
          <input v-model="info.agentAddress"/>

          <div class="submit" @click="submit">提交</div>

          <p class="line-us">联系我们：</p>
          <p>周老师：18868062653 </p>
          <p>固定电话：15057060226</p>
        </div>
      </div>
      <Tips :tip.sync="msg" />
    </div>
</template>

<script>
import { getQuery, baseUrl, upFile } from '../../common/util';
export default {
  components: {
    
  },
  data () {
      return {
        active: 0,
        msg: '',
        token: '',
        info: {
          companyName: '',
          certId: '',
          principalName: '',
          mobile: '',
          cardId: '',
          agentArea: '',
          perTrainNumber: '',
          agentAddress: '',
        }
      }
  },
  beforeMount() {
    this.token = getQuery('token');
    if (this.token) {
      localStorage.setItem('ng-token', this.token);
    }
  },
  mounted() {
    // console.log(this.props);
  },
  methods: {
      submit() {
        if (!this.info.companyName) {
          alert('公司名称不能为空')
          return;
        }
        if (!this.info.certId) {
          alert('信用代码不能为空')
          return;
        }
        if (!this.info.principalName) {
          alert('校长名称不能为空')
          return;
        }
        if (!this.info.mobile) {
          alert('手机号不能为空')
          return;
        }
        if (this.info.mobile.length !== 11) {
          alert('手机号长度不正确')
          return;
        }
        if (!this.info.cardId) {
          alert('身份证号码不能为空')
          return;
        }
        if (!this.info.agentArea) {
          alert('代理区域不能为空')
          return;
        }       
        if (!this.info.perTrainNumber) {
          alert('培训人数不能为空')
          return;
        }
        if (!this.info.agentAddress) {
          alert('地址不能为空')
          return;
        }
        this.$http.post('/api/agentInfo/save', this.info)
        .then((res) => {
            this.msg = '提交成功';
            this.info = {
              companyName: '',
              certId: '',
              principalName: '',
              mobile: '',
              cardId: '',
              agentArea: '',
              perTrainNumber: '',
              agentAddress: '',
            }
        }).catch((err) => {
            this.validErrorInfo = err.errorMsg;
        })
      }
  }

}
</script>

<style lang="less" scoped>
    .jion {
      background-color: #f1f8d9;
      min-height: 100vh;
      padding: 0.3rem;
      box-sizing: border-box;
      .cn {
        background-color: #e2f1e8;
        padding: 0.2rem;
        box-sizing: border-box;
        .chang {
          display: flex;
          justify-content: space-between;
          .item {
            &.active {
              background-color: #274778;
            }
            background-color: #c9d7ef;
            width: 3.2rem;
            height: 1rem;
            line-height: 1rem;
            text-align: center;
            color: white;
            font-size: 0.32rem;
          }
        }
        .active0 {
          margin-top: 0.2rem;
          p {
            font-size: 0.32rem;
            line-height: 0.6rem;
          }
          .name {
            background-color: #c9d7ef;
            font-size: 0.36rem;
            margin: 0.1rem auto;
          }
        }
        .active1 {
          margin-top: 0.2rem;
          p {
            font-size: 0.3rem;
            line-height: 0.6rem;
          }
          input {
            width: 100%;
            height: 0.6rem;
            margin-bottom: 0.2rem;
          }
          .submit {
            background-color: #4394ed;
            width: 3rem;
            height: 0.9rem;
            line-height: 0.9rem;
            color: white;
            text-align: center;
            font-size: 0.36rem;
            margin: 0.3rem auto;
          }
        }
      }
    }
</style>
